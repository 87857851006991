<template>

  <div>

    <!-- MODAL : KOLLEKTOR DOWNLOAD -->

    <Modal ref="addDevicesModal">
      <template v-slot:header>
        <h5 class="modal-title"><i class="fas fa-plus fas-left"></i> LET'S CONNECT <small class="color01">_ <translate>YOUR ASICs</translate></small></h5>
      </template>
      <template v-slot:body>
        <div><i class="grow fas fa-arrow-down fas-small fas-left" style="font-size: 4em;"></i></div>
        <div class="pulsate" style="letter-spacing: 2px; font-size: 1em; margin-top: 9px;"> <translate>GET THE CLOUD.KOLLEKTOR</translate>
        </div>
      </template>
      <template v-slot:footer>
        <div style="display:inline-flex;">
          <a href="https://get.farmgod.cloud/FARMGOD.Kollektor.Linux.amd64.tar.gz" target="_blank" title="Download the 'FARMGOD|CLOUD.Kollektor' (Linux/x86)" style="margin: auto;margin-right:5px; width: 100%">
            <button type="button" class='btn btn-success trans pulsate' @click="$refs.addDevicesModal.closeModal();$refs.infoModal.openModal();">
              <i class="fas fas-xsBtn fa-download fas-left"></i><b>FARMGOD.Kollektor.Linux.tar.gz</b>
            </button>
          </a>
          <button type="button" class='btn btn-primary trans pulsate' @click="copy('wget --no-check-certificate https://get.farmgod.cloud/FARMGOD.Kollektor.Linux.amd64.tar.gz');$refs.addDevicesModal.closeModal();$refs.infoModal.openModal();" style="margin-left:5px;width: 100%">
            <i class="fas fas-xsBtn fa-copy fas-left"></i><b>COPY 'WGET-CMD' TO CLIPBOARD</b>
          </button>
        </div>
      </template>
    </Modal>

    <!-- MODAL : iNFOS -->

    <Modal ref="infoModal">
      <template v-slot:header>
        <h5 class="modal-title"><i class="fas fa-plus fas-left"></i> LET'S CONNECT <small class="color01">_ <translate>YOUR ASICs</translate></small></h5>
      </template>
      <template v-slot:body>
        <div class="fI-slow">
          <div v-translate style="margin: 5px 30px 15px 30px; color: #fff;">
            The <b class="grey">'Kollektor'</b> is a tool that connects your ASICs and the 'FARMGOD|CLOUD' platform, <b>streamlining data collection</b> and performing essential tasks.
          </div>
          <div v-translate>
            <b class="green">Get started &raquo;</b> Download/extract the .tar.gz and check out the <a href="https://get.farmgod.cloud/FARMGOD.Kollektor.Linux.QuickStart.Guide.txt" title="View the README online." target="_blank"><b>README</b></a>!
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div style="display:inline-flex;width:80%;">
          <button class='next-btn btn btn-secondary trans pulsate' @click="$refs.infoModal.closeModal();$refs.addDevicesModal.openModal();"><i class="fas fas-xsBtn fa-download fas-left"></i> <translate>DOWNLOAD</translate></button>
          <button :value="$gettext('CLOSE')" class='btn btn-primary' @click="$refs.infoModal.closeModal();"><i class="grow fas fa-times fas-small fas-left"></i><translate>CLOSE</translate></button>
        </div>
      </template>
    </Modal>

  </div>

</template>

<script>
import Modal from '@/components/widgets/Modal.vue'
import { PRODUCT_MODE } from '@/common/config'
import Farm from '@/models/farm'

export default {
  name: 'DeviceImporterCloud',
  props: ['farm'],
  components: { Modal },
  data () {
    return {
      product_mode: PRODUCT_MODE
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1).toUpperCase()
    }
  },
  created () {
  },
  computed: {
    devicesetID () {
      if (!this.farm) {
        var farm = Farm.query().where({ visible: true }).last()
        return farm
      } else {
        return this.farm
      }
    }
  },
  methods: {
    async copy (content) {
      await navigator.clipboard.writeText(content)
    }
  }
}
</script>
