<template>

  <div class="container-fluid">

    <div class='row infoBox'>

      <div class='col-sm-12'>
        <!--CoinTicker ref="CoinTicker" v-if='product_mode === "pro"'/-->
      </div>

    </div>

    <div class="row m-3">

      <div class="col-md-12">

        <div class="copyright_alt" >

          <a href="https://farmgod.io" title="Visit the FarmGod website." target="_blank" style="color:rgb(162 162 162)!important;margin-right: 3px;"><b>FarmGod.io</b></a>

          <span class="color08"> // </span>

          <a href="https://t.me/farmgod_io" :title="$gettext('Chat with FarmGod on Telegram.')" target="_blank" class="social-link-footer in-header last w-inline-block" style="margin: 1px 5px 0 3px;">
            <img src="@/assets/img_core/social/tg.sm.png" width="12" alt="telegram" style="margin-top: -1px;">
          </a>
          <a href="https://youtube.com/@farmgod_io" :title="$gettext('Follow FarmGod on Youtube.')" target="_blank" class="social-link-footer in-header w-inline-block" style="margin: 1px 5px 0 0px;">
            <img src="@/assets/img_core/social/yt.sm.png" width="12" alt="youtube" style="margin-top: -1px;">
          </a>
          <a href="https://twitter.com/farmgod_io" :title="$gettext('Follow FarmGod on Twitter/X.')" target="_blank" class="social-link-footer in-header w-inline-block" style="margin: 1px 5px 0 0px;">
            <img src="@/assets/img_core/social/tw.sm.png" width="13" alt="twitter" style="margin-top: -1px;">
          </a>
          <img src="@/assets/img_core/social/mail.sm.png" width="12" title="Subscribe to the Newsletter." @click="$refs.NewsletterSub.$refs.nlSub.openModal();" style="margin: 0px 5px 0 0px; cursor: pointer;">

          <br v-if="isMobile()">

          <span v-if="!isMobile()" class="color08" style="margin-right:2px;">// </span>
          <span :title='$gettext("Logged in as:") + " " + currentUser.email'><i class="fas fa-user fas-small fas-left" style="margin-right:-1px; margin-left:2px;"></i>{{ (currentUser.email ? currentUser.email : 'n/a') }}</span>

          <UserSettings ref="UserSettings" />
          <NewsletterSub ref="NewsletterSub" />

        </div>

      </div>

    </div>

  </div>

</template>

<script>
import { AUTH__LOGOUT } from '@/store/actions.type'
import { mapGetters } from 'vuex'
import UserSettings from '@/components/widgets/UserSettings.vue'
import NewsletterSub from '@/components/widgets/NewsletterSub.vue'
// import CoinTicker from '@/components/widgets/CoinTicker.vue'
import { PRODUCT_MODE } from '@/common/config'

export default {
  name: 'LoggedInFooter',
  components: { UserSettings, NewsletterSub },
  computed: {
    ...mapGetters(['currentUser'])
  },
  data () {
    return {
      product_mode: PRODUCT_MODE
    }
  },
  methods: {
    isMobile () {
      if (/Android|webOS|iPhone|iPod|iPad|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    logout () {
      this.$store.dispatch(AUTH__LOGOUT).then(() => {
        this.$router.push({ name: 'login' })
      })
    }
  }
}
</script>
