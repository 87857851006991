<template>

  <div>

    <!-- MODAL : EDIT FARM -->

    <Modal ref="editFarm">

      <template v-slot:header>
        <h5 class="modal-title"><i class="fas fa-cogs fas-left"></i> <translate>DEVICE-SET</translate> <small class="color01">_ <translate>EDITOR</translate></small></h5>
      </template>
      <template v-slot:body>
        <div id="editFarm" style="margin: -10px 0 -15px 0;">
          <fieldset class="configGrpFarmEdit">
            <label ref='basicConfig' class="wideLabel"><i class="fas fas-xsBtn fas-left fa-users-cog"></i><translate>BASIC SETTINGS AND INFORMATION</translate></label>
            <div class='field-with-right-button'>
              <FormulateInput
                  disabled='disabled'
                  :label="$gettext('DEVICE-SET ID')"
                  :tooltip="$gettext('This is the unique identifier of the device-set and can not be changed.')"
                  type='text'
                  v-model='farm_edit_obj.deviceset_id'
                  :placeholder="$gettext('Waiting for server... Please wait!')"
              />
              <button style="float: right;height: 30px;width: 30px;margin-left: 5px;margin-right: 0px;" class='btn btn-primary' @click.prevent="copyDeviceSetId()" :title="$gettext('Copy the Device-Set ID to clipboard.')"><i class="fas fas-xsBtn fa-copy"></i></button>
            </div>
            <FormulateInput
              :label="$gettext('NAME')"
              :tooltip="$gettext('Enter the desired name for this device-set. (Min chars 3, max chars 16 and alphanumeric.)')"
              type='text'
              name='name'
              v-model='farm_edit_obj.name'
              placeholder='SECTION_01'
            />
            <span v-if='product_mode === "pro"'>
              <label ref='supportConfig' class="wideLabel"><i class="fas fas-xsBtn fas-left fa-user-shield"></i><translate>REMOTE ASSISTANCE</translate></label>
              <FormulateInput
                  :label="$gettext('REMOTE SUPPORT')"
                  :tooltip="$gettext('If enabled, your device-set will show up in an admins account for remote assistance. Keep unchecked to disable remote support.')"
                  name='enabled'
                  class="enabledCheck"
                  type='checkbox'
                  label-position="before"
                  v-model='farm_edit_obj.remote_support_enabled'
              />
            </span>
          </fieldset>
        </div>
      </template>
      <template v-slot:footer>
        <div style="display:inline-flex;width:80%;">
          <button class='btn btn-primary trans' @click="$refs.editFarmExtended.openModal();$refs.editFarm.closeModal();"><i class="fas fas-xsBtn fa-cogs fas-left"></i><translate>ADVANCED</translate></button>
          <button v-if='farm_edit_obj.name && farm_edit_obj.name.length >= 2 && farm_edit_obj.name.length <= 16' type='submit' form='editFarm' class='btn btn-success trans' @click="editFarm();$refs.editFarm.closeModal();"><i class="fas fas-xsBtn fa-save fas-left"></i><translate>SAVE</translate></button>
          <button v-else class='btn btn-primary trans' style="opacity: 0.2; cursor: not-allowed;" :title="$gettext('Enter a valid worker name...')"><i class="fas fas-xsBtn fa-save fas-left"></i><translate>SAVE</translate></button>
        </div>
      </template>
    </Modal>

    <!-- MODAL : EDIT EXTENDED -->

    <Modal ref="editFarmExtended">
      <template v-slot:header>
        <h5 class="modal-title"><i class="fas fa-cogs fas-left"></i> <translate>DEVICE-SET</translate> <small class="color01">_ <translate>EDITOR</translate></small></h5>
      </template>
      <template v-slot:body>
        <div id="editFarmExtended" style="margin: -10px 0 -15px 0;">
          <fieldset class="configGrpFarmEdit" v-if='product_mode == "pro"'>
            <label ref='basicConfig' class="wideLabel"><i class="fas fas-xsBtn fas-left fa-hammer"></i><translate>WORKER NAME SETUP</translate></label>
            <FormulateInput
                v-if="workerModeDelimiter == 0"
                :label="$gettext('WORKER NAME MODE')"
                type='select'
                :tooltip="$gettext('Choose between having one worker name for all devices (device-set name) or having individual worker names per device (eg. deviceset_ip3_ip4).')"
                :placeholder="$gettext('Please choose...')"
                :options="{
                  0: 'SINGLE (USE DEVICE-SET NAME FOR ALL DEVICES)',
                  32768: 'PER DEVICE (IP4)',
                  49152: 'PER DEVICE (IP3xIP4)',
                  57344: 'PER DEVICE (IP2xIP3xIP4)',
                  32784: 'PER DEVICE (DEVICESETxIP4)',
                  49168: 'PER DEVICE (DEVICESETxIP3xIP4)',
                  57360: 'PER DEVICE (DEVICESETxIP2xIP3xIP4)',
                  65536: 'PER DEVICE (DEVICE-ID / MANUAL OVERRIDE)'
                }"
                v-model='workerMode'
            />
            <FormulateInput
                v-if="workerModeDelimiter == 1"
                :label="$gettext('WORKER NAME MODE')"
                type='select'
                :placeholder="$gettext('Please choose...')"
                :tooltip="$gettext('Choose between having one worker name for all devices (device-set name) or having individual worker names per device (eg. deviceset_ip3_ip4).')"
                :options="{
                  0: 'SINGLE (USE DEVICE-SET NAME FOR ALL DEVICES)',
                  1081344: 'PER DEVICE (IP4)',
                  1097728: 'PER DEVICE (IP3_IP4)',
                  1105920: 'PER DEVICE (IP2_IP3_IP4)',
                  1081360: 'PER DEVICE (DEVICESET_IP4)',
                  1097744: 'PER DEVICE (DEVICESET_IP3_IP4)',
                  1105936: 'PER DEVICE (DEVICESET_IP2_IP3_IP4)',
                  1114112: 'PER DEVICE (DEVICE-ID / MANUAL OVERRIDE)'
                }"
                v-model='workerMode'
                />
            <FormulateInput
                :label="$gettext('DELIMITER')"
                :tooltip="$gettext('Choose between using \'x\' (default) or \'_\' as delimiter for worker name assembly.')"
                type='select'
                v-model='workerModeDelimiter'
                v-on:change='inputWatcher'
                :options='{ 0: "x", 1: "_" }'
            />
            <!--div class='form-group fI' v-if="workerMode === 1081360 || workerMode === 1097744 || workerMode === 1105936">
              <div class="fI-slow" style="width: 70%;margin:auto;">
                To <b>manually override</b> the worker name of devices, go to <b>Miners</b> and click the <i class="fas fas-xsBtn fa-cog"></i> icon next to the worker name in the list.
              </div>
            </div-->
            <div class='form-group fI' v-if="workerMode != 65536 && workerMode != 1114112">
              <div v-if="workerMode != 'null'">
                <FormulateInput key="fs_wm_preview1" :label="$gettext('PREVIEW')" :tooltip="$gettext('This is a preview of how your asic(s) will show up on the mining pool.')" v-if="workerMode == 0" disabled='disabled' type='text' :value="farm_edit_obj.name.split('_').join('x')" style="opacity: 0.55;" />
                <FormulateInput key="fs_wm_preview2" :label="$gettext('PREVIEW')" :tooltip="$gettext('This is a preview of how your asic(s) will show up on the mining pool.')" v-if="workerMode == 32768" disabled='disabled' type='text' value="219" style="opacity: 0.55;" />
                <FormulateInput key="fs_wm_preview3" :label="$gettext('PREVIEW')" :tooltip="$gettext('This is a preview of how your asic(s) will show up on the mining pool.')" v-if="workerMode == 32784" disabled='disabled' type='text' :value="farm_edit_obj.name.split(' ').join('x').split('_').join('x') + 'x219'" style="opacity: 0.55;" />
                <FormulateInput key="fs_wm_preview4" :label="$gettext('PREVIEW')" :tooltip="$gettext('This is a preview of how your asic(s) will show up on the mining pool.')" v-if="workerMode == 49152" disabled='disabled' type='text' value="1x219" style="opacity: 0.55;" />
                <FormulateInput key="fs_wm_preview5" :label="$gettext('PREVIEW')" :tooltip="$gettext('This is a preview of how your asic(s) will show up on the mining pool.')" v-if="workerMode == 49168" disabled='disabled' type='text' :value="farm_edit_obj.name.split(' ').join('x').split('_').join('x') + 'x1x219'" style="opacity: 0.55;" />
                <FormulateInput key="fs_wm_preview6" :label="$gettext('PREVIEW')" :tooltip="$gettext('This is a preview of how your asic(s) will show up on the mining pool.')" v-if="workerMode == 57344" disabled='disabled' type='text' value="5x1x219" style="opacity: 0.55;" />
                <FormulateInput key="fs_wm_preview7" :label="$gettext('PREVIEW')" :tooltip="$gettext('This is a preview of how your asic(s) will show up on the mining pool.')" v-if="workerMode == 57360" disabled='disabled' type='text' :value="farm_edit_obj.name.split(' ').join('x').split('_').join('x') + 'x5x1x219'" style="opacity: 0.55;" />
                <FormulateInput key="fs_wm_preview8" :label="$gettext('PREVIEW')" :tooltip="$gettext('This is a preview of how your asic(s) will show up on the mining pool.')" v-if="workerMode == 1048576" disabled='disabled' type='text' :value="farm_edit_obj.name.split(' ').join('_')" style="opacity: 0.55;" />
                <FormulateInput key="fs_wm_preview9" :label="$gettext('PREVIEW')" :tooltip="$gettext('This is a preview of how your asic(s) will show up on the mining pool.')" v-if="workerMode == 1081344" disabled='disabled' type='text' value="219" style="opacity: 0.55;" />
                <FormulateInput key="fs_wm_preview10" :label="$gettext('PREVIEW')" :tooltip="$gettext('This is a preview of how your asic(s) will show up on the mining pool.')" v-if="workerMode == 1081360" disabled='disabled' type='text' :value="farm_edit_obj.name.split(' ').join('_') + '_219'" style="opacity: 0.55;" />
                <FormulateInput key="fs_wm_preview11" :label="$gettext('PREVIEW')" :tooltip="$gettext('This is a preview of how your asic(s) will show up on the mining pool.')" v-if="workerMode == 1097728" disabled='disabled' type='text' value="1_219" style="opacity: 0.55;" />
                <FormulateInput key="fs_wm_preview12" :label="$gettext('PREVIEW')" :tooltip="$gettext('This is a preview of how your asic(s) will show up on the mining pool.')" v-if="workerMode == 1097744" disabled='disabled' type='text' :value="farm_edit_obj.name.split(' ').join('_') + '_1_219'" style="opacity: 0.55;" />
                <FormulateInput key="fs_wm_preview13" :label="$gettext('PREVIEW')" :tooltip="$gettext('This is a preview of how your asic(s) will show up on the mining pool.')" v-if="workerMode == 1105920" disabled='disabled' type='text' value="5_1_219" style="opacity: 0.55;" />
                <FormulateInput key="fs_wm_preview14" :label="$gettext('PREVIEW')" :tooltip="$gettext('This is a preview of how your asic(s) will show up on the mining pool.')" v-if="workerMode == 1105936" disabled='disabled' type='text' :value="farm_edit_obj.name.split(' ').join('_') + '_5_1_219'" style="opacity: 0.55;" />
              </div>
            </div>
            <div class='form-group fI' v-if="workerMode == 65536 || workerMode == 1114112">
              <div class="fI-slow" style="width: 70%;margin:auto;" v-translate>
                To <b>manually override</b> the worker name of devices, go to <b>Miners</b> and click the <i class="fas fas-xsBtn fa-cog"></i> icon next to the worker name in the list.
              </div>
            </div>
            <div class='form-group fI' v-if="workerModeDelimiter == 1 && workerMode == 'null'">
              <div class="fI-slow" style="width: 70%;margin:auto;" v-translate>
                <b>ATTENTION:</b> Using _ as delimiter could lead to <b>pool connection issues</b> (eg. AntPool). Use with caution!
              </div>
            </div>
          </fieldset>
          <fieldset class="configGrpFarmEdit">
            <label ref='basicConfig' class="wideLabel"><i class="fas fas-xsBtn fas-left fa-bolt"></i><translate>ELECTRiCiTY</translate></label>
            <FormulateInput
                :label="$gettext('COST/KWH')"
                type='text'
                :tooltip="$gettext('Cost per kWh.')"
                v-model='farm_edit_obj.price_kwh'
                placeholder='eg. 0.045'
            />
            <FormulateInput
                :label="$gettext('CURRENCY')"
                :tooltip="$gettext('Currency in which electricity bills are settled.')"
                type='select'
                v-model='farm_edit_obj.currency'
                :options="{ BTC: 'B', EUR: '€', USD: '$' }"
                style="width: auto;"
            />
          </fieldset>
        </div>
      </template>
      <template v-slot:footer>
        <div style="display:inline-flex;width:80%;">
          <button class='btn btn-primary trans' @click="$refs.editFarm.openModal();$refs.editFarmExtended.closeModal();"><i class="fas fas-xsBtn fa-cog fas-left"></i><translate>BASIC</translate></button>
          <button v-if='farm_edit_obj.name && farm_edit_obj.name.length >= 2 && farm_edit_obj.name.length <= 16' type='submit' form='editFarm' class='btn btn-success trans' @click="editFarm();$refs.editFarmExtended.closeModal();"><i class="fas fas-xsBtn fa-save fas-left"></i><translate>SAVE</translate></button>
          <button v-else class='btn btn-primary trans' style="opacity: 0.2; cursor: not-allowed;" :title="$gettext('Enter a valid farm name...')"><i class="fas fas-xsBtn fa-save fas-left"></i><translate>SAVE</translate></button>
        </div>
      </template>
    </Modal>

    <!-- MODAL : CONFIG UPDATE : SUCCESS -->

    <Modal ref="configUpdateSuccess">
      <template v-slot:header>
        <h5 class="modal-title"><i class="fas fa-check-circle fas-left"></i> <translate>INFO</translate> <small class="color01">_ <translate>CONFIGURATION</translate></small></h5>
      </template>
      <template v-slot:body>
        <form id="enableTelemetryForm">
          <div class="canvas-wrapper" style="height: auto;opacity: 1;background: none !important;border: none !important;margin-top: -15px;margin-bottom: -15px;">
            <div><i class="grow fas fa-check fas-small fas-left" style="font-size: 3em; color: #13A454;"></i></div>
            <div class="pulsate" style="letter-spacing: 3px; font-size: 1em; margin-top: 9px; margin-bottom: 10px;color: #13A454;"> <translate>UPDATE SUCCESSFUL</translate> </div>
            <div style="width: 60%; margin: auto;"><small><span class="grey"><translate>During the configuration update process, data (eg. pool status, stats) might be displayed inaccurately for several minutes.</translate></span></small></div>
          </div>
        </form>
      </template>
      <template v-slot:footer>
        <div style="display:inline-flex;width:80%;">
          <button :value="$gettext('CLOSE')" class='btn btn-primary' @click="$refs.configUpdateSuccess.closeModal();"><i class="grow fas fa-times fas-small fas-left"></i><translate>CLOSE</translate></button>
        </div>
      </template>
    </Modal>

    <!-- MODAL : CONFIG UPDATE : ERROR -->

    <Modal ref="configUpdateError">
      <template v-slot:header>
        <h5 class="modal-title"><i class="fas fa-exclamation-triangle fas-left"></i> <translate>ERROR</translate> <small class="color01">_ <translate>CONFIGURATION</translate></small></h5>
      </template>
      <template v-slot:body>
        <form id="enableTelemetryForm">
          <div class="canvas-wrapper" style="height: auto;opacity: 1;background: none !important;border: none !important;margin-top: -15px;margin-bottom: -15px;">
            <div class="canvas-wrapper" style="height: auto;opacity: 1;background: none !important;border: none !important;margin-top: -15px;margin-bottom: -15px;">
              <div><i class="grow fas fa-times fas-small fas-left red" style="font-size: 4em;"></i></div>
              <div class="pulsate red" style="letter-spacing: 2px; font-size: 1em; margin-top: 9px;"> <translate>UPDATE ERROR</translate> </div>
              <div style="margin-top:12px; font-size: 1em; width: 60%; margin-left: auto; margin-right:auto;">{{ errorDetail }}</div>
            </div>
          </div>
        </form>
      </template>
      <template v-slot:footer>
        <div style="display:inline-flex;width:80%;">
          <button class='btn btn-primary' @click="$refs.configUpdateError.closeModal();"><i class="grow fas fa-times fas-small fas-left"></i><translate>CLOSE</translate></button>
        </div>
      </template>
    </Modal>

  </div>

</template>

<script>
// import { FARM__UPDATE, FARM__READ_MINER_CONFIG, MINER_CONFIG__UPDATE } from '@/store/actions.type'
import { FARM__UPDATE } from '@/store/actions.type'
import Modal from '@/components/widgets/Modal.vue'
import { PRODUCT_MODE } from '@/common/config'

export default {
  name: 'FarmSettings',
  props: ['farm', 'worker_mode'],
  components: { Modal },
  data () {
    return {
      workerMode: this.worker_mode,
      workerModeDelimiter: this.worker_mode === 0 || this.worker_mode === 32784 || this.worker_mode === 49168 || this.worker_mode === 57360 || this.worker_mode === 65536 ? 0 : 1,
      config: null,
      configId: null,
      product_mode: PRODUCT_MODE,
      farm_edit_obj: this.farm
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1).toUpperCase()
    },
    currencySymbol: function (value) {
      if (value === 'BTC') {
        return '₿'
      } else if (value === 'EUR') {
        return '€'
      } else {
        return '$'
      }
    }
  },
  created () {
    /*
    this.$store.dispatch(FARM__READ_MINER_CONFIG, this.farm.deviceset_id).then((minerConfigFarmSettings) => {
      this.config = minerConfigFarmSettings
      this.configId = minerConfigFarmSettings.id
    })
    */
  },
  methods: {
    async editFarm () {
      await this.$store.dispatch(FARM__UPDATE, { id: this.farm_edit_obj.deviceset_id, params: { name: this.farm_edit_obj.name.trim().split(' ').join('_'), worker_mode: this.workerMode, remote_support_enabled: this.farm_edit_obj.remote_support_enabled, price_kwh: this.farm_edit_obj.price_kwh, currency: this.farm_edit_obj.currency, is_active: true, owners: [{ email: this.farm_edit_obj.owners }] } }).then((result) => {
        this.$refs.configUpdateSuccess.openModal()
      }).catch((err) => {
        this.errorDetailFull = JSON.parse(JSON.stringify(err.response.data))
        this.errorDetail = this.errorDetailFull.detail[0].msg
        this.$refs.configUpdateError.openModal()
      })
    },
    inputWatcher () {
      this.workerMode = 0
    },
    async copyDeviceSetId () {
      await navigator.clipboard.writeText(this.farm_edit_obj.deviceset_id)
    } /*,
    forceUpdate () {
      this.$store.dispatch(MINER_CONFIG__UPDATE, { configId: this.configId, configData: this.config })
    } */
  }
}
</script>
