<template>

  <div>

    <!-- MODAL : IMPORT DEVICES - WELCOME -->

    <Modal ref="addDevicesModal">
      <template v-slot:header>
        <h5 class="modal-title"><i class="fas fa-plus fas-left"></i> {{ devicesetID.name }} <small class="color01">_ <translate>DEVICE IMPORTER</translate></small></h5>
      </template>
      <template v-slot:body>
        <div><i class="grow fas fa-arrow-down fas-small fas-left" style="font-size: 4em;"></i></div>
        <div class="pulsate" style="letter-spacing: 2px; font-size: 1em; margin-top: 9px;"> <translate>CHOOSE A METHOD!</translate>
        </div>
      </template>
      <template v-slot:footer>
        <div style="display:inline-flex;width:80%;">
          <button type="button" class='btn btn-secondary trans' @click="$refs.addDevicesModal.closeModal();$refs.csvModal.openModal();importTotalCount = 0; parsed = 0;"><i class="fas fas-xsBtn fa-file-export fas-left"></i><translate>MANUAL / CSV-IMPORT</translate></button>
          <button type="button" class='btn btn-secondary trans' @click="$refs.addDevicesModal.closeModal();$refs.networkScanModal.openModal();"><i class="fas fas-xsBtn fa-network-wired fas-left"></i><translate>AUTOMATIC / NETWORK-SCAN</translate></button>
        </div>
      </template>
    </Modal>

    <!-- MODAL : IMPORT DEVICES - CSV -->

    <Modal ref="csvModal">
      <template v-slot:header>
        <h5 class="modal-title"><i class="fas fa-file-export fas-left"></i> CSV-FILE <small class="color01">_ <translate>IMPORTER</translate></small></h5>
      </template>
      <template v-slot:body>
        <!--div class="pulsate green grow" style="cursor: pointer; font-weight: normal;" title="Start the scanner..."><i class="fas fa-search-plus fas-small fas-left" style="font-size: 4em;"></i></div-->
          <FormulateInput
              :tooltip="$gettext('Select a .csv file to upload...')"
              type="file"
              accept=".csv"
              style="margin: -5px auto -4px auto;background: rgb(35, 35, 35);width: 50%;color: rgb(198, 198, 198);border: 1px solid rgb(51, 51, 51);border-radius: 5px;padding: 5px;"
              @change="handleFileUpload($event)"
          />
          <!--table v-if="parsed" style="width: 100%;">
            <thead>
                <tr>
                    <th v-for="(header, key) in content.meta.fields"
                        v-bind:key="'header-'+key">
                        {{ header }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(row, rowKey) in content.data"
                    v-bind:key="'row-'+rowKey">
                        <td v-for="(column, columnKey) in content.meta.fields"
                            v-bind:key="'row-'+rowKey+'-column-'+columnKey">
                                <input v-model="content.data[rowKey][column]"/>
                        </td>
                </tr>
            </tbody>
        </table-->
        <!--div class="pulsate green" style="letter-spacing: 2px; font-size: 1em; margin-top: 9px;"> <translate>CLICK TO SCAN</translate>
        </div-->
      </template>
      <template v-slot:footer>
        <div style="display:inline-flex;width:80%;">
          <button class='next-btn btn btn-secondary trans' @click="$refs.csvModal.closeModal();$refs.addDevicesModal.openModal();"><i class="fas fas-xsBtn fa-angle-double-left fas-left"></i> <translate>BACK</translate></button>
          <button v-if='parsed' class='next-btn btn btn-success trans' :title="$gettext('Import \'' + importTotalCount + '\' devices into set: ' + devicesetID.name + '')" @click="importCSV()"><translate>IMPORT</translate> ({{ importTotalCount }})<i class="fas fas-xsBtn fa-file-export fas-right"></i></button>
          <button v-else class='next-btn btn btn-primary trans' style="opacity: 0.2; cursor: not-allowed;" :title="$gettext('Choose a file first...')"><translate>IMPORT</translate> <i class="fas fas-xsBtn fa-file-export fas-right"></i></button>
        </div>
      </template>
    </Modal>

    <!-- MODAL : IMPORT DEVICES - CSV - DONE -->

    <Modal ref="csvModalDone">
      <template v-slot:header>
        <h5 class="modal-title"><i class="fas fa-file-export fas-left"></i> IMPORT <small class="color01">_ <translate>DONE</translate></small></h5>
      </template>
      <template v-slot:body>
        <div>
          ADDED: <span :class="importSuccessCount == 0 ? 'red' : 'green'">{{ importSuccessCount }}</span> | SKIPPED: <span :class="importErrorCount == 0 ? 'green' : 'red'">{{ importErrorCount }}</span> | TOTAL: <span :class="importTotalCount == 0 ? 'red' : 'white'">{{ importTotalCount }}</span>
        </div>
      </template>
      <template v-slot:footer>
        <div style="display:inline-flex;width:80%;">
          <button class='next-btn btn btn-secondary trans' @click="importTotalCount = 0; parsed = 0; $refs.csvModalDone.closeModal();$refs.csvModal.openModal();"><i class="fas fas-xsBtn fa-file-export fas-left"></i> <translate>IMPORT ANOTHER FILE</translate></button>
          <!--button class='next-btn btn btn-secondary trans' @click="importTotalCount = 0; parsed = 0; $refs.csvModalDone.closeModal()"><i class="fa fas-xsBtn fa-th-large fas-left"></i> <translate key="afmf_51">RETURN TO OVERVIEW</translate></button-->
          <button class='next-btn btn btn-secondary trans' @click="importTotalCount = 0; parsed = 0; $refs.csvModalDone.closeModal(); jumpToDevices();"><i class="fa fas-xsBtn fa-server fas-left"></i> <translate key="afmf_51">SHOW MINERS</translate></button>
        </div>
      </template>
    </Modal>

    <!-- MODAL : IMPORT DEVICES - NETWORK SCAN -->

    <Modal ref="networkScanModal">
      <template v-slot:header>
        <h5 class="modal-title"><i class="fas fa-network-wired fas-left"></i> NETWORK <small class="color01">_ <translate>SCANNER</translate></small></h5>
      </template>
      <template v-slot:body>
        <!--div class="pulsate green grow" style="cursor: pointer; font-weight: normal;" title="Start the scanner..."><i class="fas fa-search-plus fas-small fas-left" style="font-size: 4em;"></i></div-->
        <FormulateInput
              :tooltip="$gettext('Scan-Target')"
              type='text'
              placeholder='Eg. "10.1.0.*" or "-p 4028 10.1.0.*"'
              name='ip_range'
              class="landingPageInput ipRangeInput"
              style="width: 100%!important; letter-spacing: 0.5px!important; font-size: 1em!important; text-align: center; margin-bottom: -6px; margin-top: -7px;"
              v-model='ipRange'
          />
        <!--div class="pulsate green" style="letter-spacing: 2px; font-size: 1em; margin-top: 9px;"> <translate>CLICK TO SCAN</translate>
        </div-->
      </template>
      <template v-slot:footer>
        <div style="display:inline-flex;width:80%;">
          <button class='next-btn btn btn-secondary trans' @click="$refs.networkScanModal.closeModal();$refs.addDevicesModal.openModal();"><i class="fas fas-xsBtn fa-angle-double-left fas-left"></i> <translate>BACK</translate></button>
          <button v-if='ipRange' class='next-btn btn btn-success trans' :title="$gettext('...')" @click="startNetScan()"><translate>SCAN</translate> <i class="fas fas-xsBtn fa-search fas-right"></i></button>
          <button v-else class='next-btn btn btn-primary trans' style="opacity: 0.2; cursor: not-allowed;" :title="$gettext('...')"><translate>SCAN</translate> <i class="fas fas-xsBtn fa-search fas-right"></i></button>
        </div>
      </template>
    </Modal>

    <!-- MODAL : IMPORT DEVICES - NETWORK SCAN - DONE -->

    <Modal ref="networkScanModalActive">
      <template v-slot:header>
        <h5 class="modal-title"><i class="fas fa-network-wired fas-left"></i> NETWORK SCAN<small class="color01">_ <translate>IN PROGRESS</translate></small></h5>
      </template>
      <template v-slot:body>
        <div class="loader loaderWaiting" style="background-color: #dadacdc7; margin: 10px auto 10px auto;"></div>
      </template>
      <template v-slot:footer>
        <div style="display:inline-flex;width:80%;">
          <button class='next-btn btn btn-secondary trans' style="opacity: 0.2; cursor: not-allowed;"><i class="fas fas-xsBtn fa-angle-double-left fas-left"></i> <translate>BACK</translate></button>
          <button class='next-btn btn btn-secondary trans' style="opacity: 0.2; cursor: not-allowed;"><translate>SCAN</translate> <i class="fas fas-xsBtn fa-search fas-right"></i></button>
        </div>
      </template>
    </Modal>

    <!-- MODAL : IMPORT DEVICES - NETWORK SCAN - DONE -->

    <Modal ref="networkScanModalDone">
      <template v-slot:header>
        <h5 class="modal-title"><i class="fas fa-network-wired fas-left"></i> IMPORT <small class="color01">_ <translate>DONE</translate></small></h5>
      </template>
      <template v-slot:body>
        <div style="margin:-5px 0 -5px 0;"><i class="grow fas fa-check fas-small fas-left" style="font-size: 3em; color: #13A454;"></i></div>
      </template>
      <template v-slot:footer>
        <div style="display:inline-flex;width:80%;">
          <button class='next-btn btn btn-secondary trans' @click="ipRange = ''; $refs.networkScanModalDone.closeModal();$refs.networkScanModal.openModal();"><i class="fas fas-xsBtn fa-network-wired fas-left"></i> <translate>SCAN AGAIN</translate></button>
          <button class='next-btn btn btn-secondary trans' @click="ipRange = ''; $refs.networkScanModalDone.closeModal(); jumpToDevices();"><i class="fa fas-xsBtn fa-server fas-left"></i> <translate key="afmf_51">SHOW MINERS</translate></button>
        </div>
      </template>
    </Modal>

  </div>

</template>

<script>
import Modal from '@/components/widgets/Modal.vue'
import { PRODUCT_MODE } from '@/common/config'
import { FARM__ADD_MINER, FARM__ADD_MINER_NETSCAN } from '@/store/actions.type'
import Farm from '@/models/farm'
import Papa from 'papaparse'

export default {
  name: 'DeviceImporter',
  props: ['farm'],
  components: { Modal },
  data () {
    return {
      ipRange: '',
      targetPort: false,
      file: '',
      content: [],
      parsed: false,
      product_mode: PRODUCT_MODE,
      importSuccessCount: 0,
      importErrorCount: 0,
      importTotalCount: 0
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1).toUpperCase()
    }
  },
  created () {
  },
  computed: {
    devicesetID () {
      if (!this.farm) {
        var farm = Farm.query().where({ visible: true }).last()
        return farm
      } else {
        return this.farm
      }
    }
  },
  methods: {
    jumpToDevices () {
      this.$router.push({ path: '/farm/' + this.devicesetID.id + '/detail/devices' })
    },
    importCSV () {
      this.content.data.forEach(
        miner => this.addMiner(
          miner.IP,
          miner.MAC,
          'dev' + miner.MAC.replaceAll(':', ''),
          'NEW',
          miner.MODEL ? miner.MODEL : '',
          miner.NOTE ? miner.NOTE : ''
        )
      )
      this.$refs.csvModal.closeModal()
      this.$refs.csvModalDone.openModal()
    },
    async addMiner (ipAddress, macAddress, deviceId, theName, modelId, minerNote) {
      // iNiT EMPTY OBJ
      const newMiner = {
        name: theName,
        note: minerNote,
        model_id: modelId,
        ip_address: ipAddress,
        mac_address: macAddress,
        os_version: '',
        platform_name: '',
        mining_software_signature: '',
        per_device_config: true,
        deviceset_id: this.devicesetID.id,
        device_id: deviceId,
        worker_name: '',
        stats_timestamp: 0,
        fanspeeds: 0,
        fanspeeds_array: [
          0
        ],
        temperatures: 0,
        temperatures_array: [
          0
        ],
        active_fans: 0,
        active_asic_chips: 0,
        active_asic_chips_array: [
          0
        ],
        active_boards: 0,
        hashrate_ghs_1m: 0,
        hashrate_ghs_5m: 0,
        pool_status: 0,
        shares_accepted: 0,
        shares_rejected: 0,
        shares_stale: 0,
        shares_invalid: 0,
        hw_errors: 0,
        total_secs_measurement: 0,
        autotuning_running: true,
        state: 0,
        pool_hashrate_5m: 0,
        pool_hashrate_1h: 0,
        pool_hashrate_12h: 0,
        pool_hashrate_24h: 0,
        error_state: 0,
        power_consumption: 0,
        pool_shares_accepted: 0,
        pool_shares_invalid: 0,
        pool_shares_stale: 0,
        pool_shares_rejected: 0,
        update_pending: true,
        config_sent_at: 0
      }

      await this.$store.dispatch(FARM__ADD_MINER, { id: this.devicesetID.id, params: newMiner }).then((result) => {
        this.importSuccessCount++
        console.log('Import success.')
      }).catch((err) => {
        this.importErrorCount++
        console.log('Import error:', JSON.parse(JSON.stringify(err.response.data)))
      })
    },
    async startNetScan () {
      this.$refs.networkScanModal.closeModal()
      this.$refs.networkScanModalActive.openModal()

      if (this.ipRange.search('p') === 1) {
        const target = this.ipRange.split(' ')

        this.ipRange = target[2]
        this.targetPort = target[1]

        await this.$store.dispatch(FARM__ADD_MINER_NETSCAN, { id: this.devicesetID.id, params: { ip_range: this.ipRange, deviceset_id: this.devicesetID.id, port: this.targetPort } }).then((result) => {
          console.log('Network-Scanner on target port ' + this.targetPort + ' done.')
          this.$refs.networkScanModalActive.closeModal()
          this.$refs.networkScanModalDone.openModal()
        }).catch((err) => {
          console.log('Network-Scanner on target port ' + this.targetPort + 'failed:', JSON.parse(JSON.stringify(err.response.data)))
        })
      } else {
        await this.$store.dispatch(FARM__ADD_MINER_NETSCAN, { id: this.devicesetID.id, params: { ip_range: this.ipRange, deviceset_id: this.devicesetID.id } }).then((result) => {
          console.log('Network-Scanner done.')
          this.$refs.networkScanModalActive.closeModal()
          this.$refs.networkScanModalDone.openModal()
        }).catch((err) => {
          console.log('Network-Scanner failed:', JSON.parse(JSON.stringify(err.response.data)))
        })
      }
    },
    handleFileUpload (event) {
      // CSV-File
      this.file = event.target.files[0]
      // Reset Vars
      this.content = []
      this.parsed = false
      this.importSuccessCount = 0
      this.importErrorCount = 0
      // Parse
      this.parseFile()
    },
    parseFile () {
      Papa.parse(this.file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          this.content = results
          this.parsed = true
          this.importTotalCount = this.content.data.length
        }.bind(this)
      })
    }
  }
}
</script>
