<template>

  <div>

    <!-- MODAL : EDIT USER -->

    <Modal ref="editUser">

      <template v-slot:header>
        <h5 class="modal-title"><i class="fas fa-user-cog fas-left"></i> <translate>ACCOUNT</translate> <small class="color01">_ <translate>SETTINGS</translate></small></h5>
      </template>
      <template v-slot:body>
        <div id="editUser" style="margin: -10px 0 -15px 0;">
          <fieldset class="configGrpFarmEdit">
            <label ref='basicConfig' class="wideLabel"><i class="fas fas-xsBtn fas-left fa-users-cog"></i><translate>BASIC INFORMATION</translate></label>
            <FormulateInput
              :label="$gettext('USERNAME')"
              :tooltip="$gettext('Your username (email address) can not be changed.)')"
              type='text'
              name='username'
              disabled='disabled'
              v-model='currentUser.email'
            />
          </fieldset>
        </div>
      </template>
      <template v-slot:footer>
        <div style="display:inline-flex;width:80%;">
          <button class='btn btn-danger' @click="logout"><i class="grow fas fa-sign-out-alt fas-small fas-left"></i><translate>LOGOUT</translate></button>
          <button class='btn btn-secondary' @click="$refs.editUser.closeModal();"><i class="grow fas fa-times fas-small fas-left"></i><translate>CLOSE</translate></button>
          <!--button class='btn btn-success' title="Save changes."><i class="fas fas-xsBtn fa-save fas-left"></i>SAVE</button-->
        </div>
      </template>
    </Modal>

    <!-- MODAL : CONFIG UPDATE : SUCCESS -->

    <Modal ref="userUpdateSuccess">
      <template v-slot:header>
        <h5 class="modal-title"><i class="fas fa-check-circle fas-left"></i> <translate>INFO</translate> <small class="color01">_ <translate>CONFIGURATION</translate></small></h5>
      </template>
      <template v-slot:body>
        <form id="enableTelemetryForm">
          <div class="canvas-wrapper" style="height: auto;opacity: 1;background: none !important;border: none !important;margin-top: -15px;margin-bottom: -15px;">
            <div><i class="grow fas fa-check fas-small fas-left" style="font-size: 4em; color: #13A454;"></i></div>
            <div class="pulsate" style="letter-spacing: 2px; font-size: 1em; margin-top: 9px; color: #13A454;"> <translate>UPDATE SUCCESSFULL</translate> </div>
          </div>
        </form>
      </template>
      <template v-slot:footer>
        <div style="display:inline-flex;width:80%;">
          <button :value="$gettext('CLOSE')" class='btn btn-primary' @click="$refs.userUpdateSuccess.closeModal();"><i class="grow fas fa-times fas-small fas-left"></i><translate>CLOSE</translate></button>
        </div>
      </template>
    </Modal>

    <!-- MODAL : CONFIG UPDATE : ERROR -->

    <Modal ref="userUpdateError">
      <template v-slot:header>
        <h5 class="modal-title"><i class="fas fa-exclamation-triangle fas-left"></i> <translate>ERROR</translate> <small class="color01">_ <translate>CONFIGURATION</translate></small></h5>
      </template>
      <template v-slot:body>
        <form id="enableTelemetryForm">
          <div class="canvas-wrapper" style="height: auto;opacity: 1;background: none !important;border: none !important;margin-top: -15px;margin-bottom: -15px;">
            <div class="canvas-wrapper" style="height: auto;opacity: 1;background: none !important;border: none !important;margin-top: -15px;margin-bottom: -15px;">
              <div><i class="grow fas fa-times fas-small fas-left red" style="font-size: 4em;"></i></div>
              <div class="pulsate red" style="letter-spacing: 2px; font-size: 1em; margin-top: 9px;"> <translate>UPDATE ERROR</translate> </div>
              <div style="margin-top:12px; font-size: 1em; width: 60%; margin-left: auto; margin-right:auto;">{{ errorDetail }}</div>
            </div>
          </div>
        </form>
      </template>
      <template v-slot:footer>
        <div style="display:inline-flex;width:80%;">
          <button class='btn btn-primary' @click="$refs.userUpdateError.closeModal();"><i class="grow fas fa-times fas-small fas-left"></i><translate>CLOSE</translate></button>
        </div>
      </template>
    </Modal>

  </div>

</template>

<script>
import { AUTH__LOGOUT } from '@/store/actions.type'
import Modal from '@/components/widgets/Modal.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'UserSettings',
  components: { Modal },
  computed: {
    ...mapGetters(['currentUser'])
  },
  methods: {
    logout () {
      this.$store.dispatch(AUTH__LOGOUT).then(() => {
        this.$router.push({ name: 'login' })
      })
    },
    async editUser () {
      console.log('basically working')
      /*
      await this.$store.dispatch(USER__UPDATE, { id: this.currentUser.id, params: { email: this.currentUser.email } }).then((result) => {
        this.$refs.userUpdateSuccess.openModal()
      }).catch((err) => {
        this.errorDetailFull = JSON.parse(JSON.stringify(err.response.data))
        this.errorDetail = this.errorDetailFull.detail[0].msg
        this.$refs.userUpdateError.openModal()
      })
      */
    }
  }
}
</script>
